import React from 'react';
import {makeStyles} from '@material-ui/core';
import TeamNeustaLogo from "./TeamNeustaLogoIcon";

const useStyles = makeStyles({
    svg: {
        width: "auto",
        height: 52,
    },
    div: {
        display: "flex",
        justifyContent: "space-between",
        width: 160,
        gap: '0.8rem',
        alignItems: 'center'
    },
    p: {
        color: "white",
        fontSize: "1rem"
    }
});

const LogoFull = () => {
    const classes = useStyles();

    return (
        <div className={classes.div}>
            <TeamNeustaLogo className={classes.svg}/>
            <p className={classes.p}>team neusta</p>
        </div>
    );
};

export default LogoFull;
