import {
    Content,
    ContentHeader,
    CreateButton,
    PageWithHeader,
    SupportButton,
    TableColumn,
    TableProps,
} from '@backstage/core-components';
import {useRouteRef} from '@backstage/core-plugin-api';
import {
    CatalogFilterLayout,
    EntityListProvider,
    UserListFilterKind,
    EntityOwnerPickerProps,
} from '@backstage/plugin-catalog-react';
import React, { ReactNode } from 'react';
import { useTranslationRef } from '@backstage/core-plugin-api/alpha';

import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import { usePermission } from '@backstage/plugin-permission-react';
import { DefaultFilters } from '@backstage/plugin-catalog-react';
import {CatalogTable, CatalogTableColumnsFunc, CatalogTableRow} from "@backstage/plugin-catalog";

import {
    createExternalRouteRef,
} from '@backstage/core-plugin-api';


import { createTranslationRef } from '@backstage/core-plugin-api/alpha';

export const catalogTranslationRef = createTranslationRef({
    id: 'catalog',
    messages: {
        indexPage: {
            title: `{{orgName}} Catalog`,
            createButtonTitle: 'Create',
        },
    },
});

export const createComponentRouteRef = createExternalRouteRef({
    id: 'create-component',
    optional: true,
});

export type BaseCatalogPageProps = {
    filters: ReactNode;
    content?: ReactNode;
    pagination?: boolean | { limit?: number };
};

export const CustomCatalogPage = (props: BaseCatalogPageProps) => {
    const { filters, content = <CatalogTable />, pagination } = props;
    const componentName = 'Software Catalog';
    const subTitle: string = 'List of Available Components';
    const createComponentLink = useRouteRef(createComponentRouteRef);
    const { t } = useTranslationRef(catalogTranslationRef);
    const { allowed } = usePermission({
        permission: catalogEntityCreatePermission,
    });

    return (
        <PageWithHeader title={componentName} themeId="home" subtitle={subTitle}>
            <Content>
                <ContentHeader title="">
                    {allowed && (
                        <CreateButton
                            title={t('indexPage.createButtonTitle')}
                            to={createComponentLink && createComponentLink()}
                        />
                    )}
                    <SupportButton>All your software catalog entities</SupportButton>
                </ContentHeader>
                <EntityListProvider pagination={pagination}>
                    <CatalogFilterLayout>
                        <CatalogFilterLayout.Filters>{filters}</CatalogFilterLayout.Filters>
                        <CatalogFilterLayout.Content>{content}</CatalogFilterLayout.Content>
                    </CatalogFilterLayout>
                </EntityListProvider>
            </Content>
        </PageWithHeader>
    );
};

export interface DefaultCatalogPageProps {
    initiallySelectedFilter?: UserListFilterKind;
    columns?: TableColumn<CatalogTableRow>[] | CatalogTableColumnsFunc;
    actions?: TableProps<CatalogTableRow>['actions'];
    initialKind?: string;
    tableOptions?: TableProps<CatalogTableRow>['options'];
    emptyContent?: ReactNode;
    ownerPickerMode?: EntityOwnerPickerProps['mode'];
    pagination?: boolean | { limit?: number };
    filters?: ReactNode;
    initiallySelectedNamespaces?: string[];
}

export function DefaultCatalogPage(props: DefaultCatalogPageProps) {
    const {
        columns,
        actions,
        initiallySelectedFilter = 'owned',
        initialKind = 'component',
        tableOptions = {},
        emptyContent,
        pagination,
        ownerPickerMode,
        filters,
        initiallySelectedNamespaces,
    } = props;

    return (
        <CustomCatalogPage
            filters={
                filters ?? (
                    <DefaultFilters
                        initialKind={initialKind}
                        initiallySelectedFilter={initiallySelectedFilter}
                        ownerPickerMode={ownerPickerMode}
                        initiallySelectedNamespaces={initiallySelectedNamespaces}
                    />
                )
            }
            content={
                <CatalogTable
                    columns={columns}
                    actions={actions}
                    tableOptions={tableOptions}
                    emptyContent={emptyContent}
                />
            }
            pagination={pagination}
        />
    );
}