import {
    createBaseThemeOptions,
    createUnifiedTheme,
    genPageTheme,
    palettes
} from '@backstage/theme';

export const neustaTheme = createUnifiedTheme({
    ...createBaseThemeOptions({
        palette: {
            ...palettes.light,
            navigation: {
                background: 'rgba(22,22,83,1)',
                indicator: 'rgba(52,101,200,1)',
                color: 'rgba(255,255,255,0.85)',
                selectedColor: 'rgba(70,129,246,1)',
            },
        },
    }),
    defaultPageTheme: 'home',
    fontFamily: 'Arial, sans-serif',
    htmlFontSize: 16,
    /* below drives the header colors */
    pageTheme: {
        home: genPageTheme({
            colors: ['rgba(15,46,104,0.9)', 'rgba(7,13,50,1)'],
            shape: 'linear-gradient(315deg, #000, #161653 60%)' })
    },
    components: {

    }
});
