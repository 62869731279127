import React from 'react';

const TeamNeustaLogo = (props:any) => (
    <svg
        className={props.className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 203 177"
    >
        <path
            fill="rgba(255,255,255,0.9)"
            display="inline-block"
            d="M163.82,48.63c-.37,0-37.52,3.17-37.52,3.17-5.22.45-8.1-3.23-6.4-8.18,0,0,6.5-19.52,6.5-21.77a16.42,16.42,0,0,0-29.88-9.41h0c-.18.29-13.2,17.44-13.2,17.44a13,13,0,0,1-14.53,3.83s-31.73-13.41-32-13.59h0a24.4,24.4,0,0,0-9.61-2.31A20.77,20.77,0,0,0,6.42,38.58c0,10.54,7,18.2,18,20.58L53,64.92c3.85.78,4,2.68.45,4.25,0,0-21.82,9.44-22,9.5a48.85,48.85,0,1,0,70.45,43.81V91.28c0-7.85,4.94-10.11,10.92-5l28.24,24h0A35,35,0,1,0,163.8,48.61Z"
        />
    </svg>
);

export default TeamNeustaLogo;