import React from 'react';
import {Navigate, Route} from 'react-router-dom';
import {apiDocsPlugin, ApiExplorerPage} from '@backstage/plugin-api-docs';
import {
    CatalogEntityPage,
    CatalogIndexPage,
    catalogPlugin,
} from '@backstage/plugin-catalog';
import {
    CatalogImportPage,
    catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import {ScaffolderPage, scaffolderPlugin} from '@backstage/plugin-scaffolder';
import {orgPlugin} from '@backstage/plugin-org';
import {SearchPage} from '@backstage/plugin-search';
import {TechRadarPage} from '@backstage/plugin-tech-radar';
import {
    TechDocsIndexPage,
    techdocsPlugin,
    TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import {TechDocsAddons} from '@backstage/plugin-techdocs-react';
import {ReportIssue} from '@backstage/plugin-techdocs-module-addons-contrib';
import {UserSettingsPage} from '@backstage/plugin-user-settings';
import {apis} from './apis';
import {entityPage} from './components/catalog/EntityPage';
import {searchPage} from './components/search/SearchPage';
import {Root} from './components/Root';

import {
    AlertDisplay,
    OAuthRequestDialog,
    SignInPage,
} from '@backstage/core-components';
import {createApp} from '@backstage/app-defaults';
import {AppRouter, FlatRoutes} from '@backstage/core-app-api';
import {CatalogGraphPage} from '@backstage/plugin-catalog-graph';
import {RequirePermission} from '@backstage/plugin-permission-react';
import {catalogEntityCreatePermission} from '@backstage/plugin-catalog-common/alpha';
import {microsoftAuthApiRef} from "@backstage/core-plugin-api";
import {UnifiedThemeProvider} from "@backstage/theme";
import {neustaTheme} from "./theme/neustaTheme";
import LightIcon from '@material-ui/icons/WbSunny';
import {DefaultCatalogPage} from "./components/catalog/CustomCatalogIndex";

const app = createApp({
    apis,
    bindRoutes({bind}) {
        bind(catalogPlugin.externalRoutes, {
            createComponent: scaffolderPlugin.routes.root,
            viewTechDoc: techdocsPlugin.routes.docRoot,
            createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
        });
        bind(apiDocsPlugin.externalRoutes, {
            registerApi: catalogImportPlugin.routes.importPage,
        });
        bind(scaffolderPlugin.externalRoutes, {
            registerComponent: catalogImportPlugin.routes.importPage,
            viewTechDoc: techdocsPlugin.routes.docRoot,
        });
        bind(orgPlugin.externalRoutes, {
            catalogIndex: catalogPlugin.routes.catalogIndex,
        });
    },
    components: {
        SignInPage: props => <SignInPage {...props} auto providers={
                [
                    {
                        id: 'github-auth-provider',
                        title: 'Azure',
                        message: 'Sign in using Azure OAuth',
                        apiRef: microsoftAuthApiRef,
                    }
                ]
        }/>,
    },
    themes: [{
        id: 'neusta-theme',
        title: 'neusta Theme',
        variant: 'light',
        icon: <LightIcon />,
        Provider: ({ children }) => (
            <UnifiedThemeProvider theme={neustaTheme} children={children} />
        ),
    }]
});

const routes = (
    <FlatRoutes>
        <Route path="/" element={<Navigate to="catalog"/>}/>
        <Route path="/catalog" element={<CatalogIndexPage />}>
            <DefaultCatalogPage />
        </Route>
        <Route
            path="/catalog/:namespace/:kind/:name"
            element={<CatalogEntityPage/>}
        >
            {entityPage}
        </Route>
        <Route path="/docs" element={<TechDocsIndexPage/>}/>
        <Route
            path="/docs/:namespace/:kind/:name/*"
            element={<TechDocsReaderPage/>}
        >
            <TechDocsAddons>
                <ReportIssue/>
            </TechDocsAddons>
        </Route>
        <Route path="/create" element={<ScaffolderPage/>}/>
        <Route path="/api-docs" element={<ApiExplorerPage/>}/>
        <Route
            path="/tech-radar"
            element={<TechRadarPage width={1500} height={800}/>}
        />
        <Route
            path="/catalog-import"
            element={
                <RequirePermission permission={catalogEntityCreatePermission}>
                    <CatalogImportPage/>
                </RequirePermission>
            }
        />
        <Route path="/search" element={<SearchPage/>}>
            {searchPage}
        </Route>
        <Route path="/settings" element={<UserSettingsPage/>}/>
        <Route path="/catalog-graph" element={<CatalogGraphPage/>}/>
    </FlatRoutes>
);

export default app.createRoot(
    <>
        <AlertDisplay/>
        <OAuthRequestDialog/>
        <AppRouter>
            <Root>{routes}</Root>
        </AppRouter>
    </>,
);
