import React from 'react';
import {makeStyles} from '@material-ui/core';
import TeamNeustaLogo from "./TeamNeustaLogoIcon";

const useStyles = makeStyles({
    svg: {
        width: 'auto',
        height: 28,
    },
});

const LogoIcon = () => {
    const classes = useStyles();

    return (
        <TeamNeustaLogo className={classes.svg}/>
    );
};

export default LogoIcon;
